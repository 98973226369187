import React, { useState, useMemo } from 'react';
import { oneOf } from 'prop-types';
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Select,
  Input,
  Stack,
  Button,
  Tbody,
  Table,
  Th,
  Tr,
  Td,
  Thead,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';

import useGuestData from '@/hooks/useGuestData';
import useDebounce from '@/hooks/useDebounce';
import copyTextToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import getBroadcastText from '../getBroadcastText';

import { THE_BRIDE, HOSTNAME } from '@/constants';
import { ENABLE_QR_INVITATION, ENABLE_SHIFT_TIME_RECEPTION, ENABLE_DIFFERENT_GUEST_PARTNER_RSVP } from '@/constants/features-flag';
import { INPUT_PROPS, SELECT_PROPS, FORM_LABEL_OPTIONS, LIST_BROADCAST, TH_PROPS } from '../types';

/**
 * Function to render List Guest Screen
 * @param {props} props
 * @returns {JSX.Element}
 */
function ListGuestScreen({ type }) {
  const toast = useToast();
  const { data: dataGuest } = useGuestData();

  const [broadcastId, setBroadcastId] = useState('');
  const [searchText, setSearchText] = useState('');
  const [showList, setShowList] = useState(20);
  const [withQR, seWithQR] = useState(ENABLE_QR_INVITATION ? true : false);

  const debounceText = useDebounce(searchText, 500);

  /**
   * Function to get list of broadcast text option
   * @returns {Object[]}
   */
  const broadcastOptions = useMemo(() => LIST_BROADCAST.filter((x) => x.type.includes(type)), [
    type,
  ]);

  const finalGuest = useMemo(() => {
    const searchKey = debounceText.toLowerCase();
    const filterLength = searchKey.length > 0 ? dataGuest.length : showList;

    const result = dataGuest.filter((item, index) => {
      const text = `${item.name.toLowerCase()} + ${item.desc.toLowerCase()} ${item.shift}`;
      return text.includes(searchKey) && index < filterLength;
    });

    return result;
  }, [dataGuest, debounceText, showList]);

  const handleCopyText = async (customText) => {
    const result = await copyTextToClipboard(customText);
    if (result) {
      toast({
        status: 'success',
        title: 'Berhasil',
        description: 'Teks berhasil disalin di clipboard.',
      });
    } else {
      toast({
        title: 'Oops!',
        description: 'Terjadi kesalahan. Silahkan muat ulang browser Anda.',
      });
    }
  };

  const handleChangeBroadcastType = (e) => {
    setBroadcastId(e.target.value);
  };

  const generateLink = (data) => {
    let finalURL = `${HOSTNAME}?to=${encodeURIComponent(data.name)}&type=invitation`;

    // if using QR code?
    if (ENABLE_QR_INVITATION && data.code) {
      finalURL = finalURL + `&code=${data.code}`;
    }

    // if using shift mode?
    if (ENABLE_SHIFT_TIME_RECEPTION) {
      finalURL = finalURL + `&shift=${data.shift}`;
    }
    
    // if using partner
    if (data.partner > 0) {
      finalURL = finalURL + `&partner=${data.partner}`;
    }

    return finalURL;
  };

  const handleClickLink = (guestInfo) => {
    const finalURL = generateLink(guestInfo);
    window.open(finalURL, '_blank');
  };

  const handleCopyLinkOnly = (guestInfo) => {
    const finalURL = generateLink(guestInfo);
    handleCopyText(finalURL);
  };

  const handleCopyTextBroadcast = (guestInfo, isWhatsApp = false) => {
    if (!broadcastId) {
      toast({
        status: 'error',
        title: 'Oops!',
        description:
          'Anda harus memilih teks broadcast terlebih dahulu sebelum menggunakan fitur ini.',
      });

      return;
    }

    const finalURL = generateLink(guestInfo);
    const finalText = getBroadcastText({
      id: broadcastId,
      link: finalURL,
      guestName: guestInfo.name,
    });

    if (isWhatsApp) {
      if (!guestInfo.hp) {
        toast({
          status: 'error',
          title: 'Oops!',
          description:
            'Nomor HP tidak tersedia. Pastikan Anda sudah memasukan nomor whatsapp di daftar tamu.',
        });

        return;
      }

      window.open(
        `https://wa.me/${guestInfo.hp}?text=${encodeURIComponent(finalText)}`,
        '_blank',
      );
      return;
    }

    handleCopyText(finalText);
  };

  return (
    <Box color="white" fontFamily="jost" paddingTop="24px">
      <Text marginTop="16px">The Wedding of</Text>
      <Text fontFamily="cursive" fontSize="3xl">
        {THE_BRIDE}
      </Text>
      <Box>
        <FormLabel {...FORM_LABEL_OPTIONS}>Teks Broadcast</FormLabel>
        <Select {...SELECT_PROPS} value={broadcastId} onChange={handleChangeBroadcastType}>
          <option style={{ color: 'black' }} value="">
            --- Pilih Text Broadcast ---
          </option>
          {broadcastOptions.map((item) => {
            return (
              <option style={{ color: 'black' }} key={item.value} value={item.value}>
                {item.name}
              </option>
            );
          })}
        </Select>
      </Box>
      {ENABLE_QR_INVITATION && false && (
        <Box>
          <FormLabel {...FORM_LABEL_OPTIONS}>Mode QR Link</FormLabel>
          <Select {...SELECT_PROPS} value={withQR} onChange={(e) => seWithQR(e.target.value)}>
            <option style={{ color: 'black' }} value={true}>
              Dengan QRCode
            </option>
            <option style={{ color: 'black' }} value={false}>
              Tanpa QRCode
            </option>
          </Select>
        </Box>
      )}
      <FormControl>
        <FormLabel {...FORM_LABEL_OPTIONS}>Cari Tamu</FormLabel>
        <Input
          {...INPUT_PROPS}
          placeholder="Cari nama/keterangan/shift tamu.."
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Text
          fontSize="sm"
          margin="4px 0"
        >{`Menampilkan ${finalGuest.length} tamu dari total ${dataGuest.length}`}</Text>
      </FormControl>
      {/* SECTION TABLE LIST */}
      <Box overflow="auto" marginTop="24px">
        <Table width="100%" variant="unstyled" size="sm">
          <Thead borderBottom="2px solid white" borderTop="2px solid white">
            <Tr>
              <Th {...TH_PROPS}>No</Th>
              <Th {...TH_PROPS}>Name</Th>
              <Th {...TH_PROPS}>Desc</Th>
              <Th {...TH_PROPS}>Shift</Th>
              {ENABLE_DIFFERENT_GUEST_PARTNER_RSVP && (
                <Th {...TH_PROPS}>Jumlah Tamu</Th>
              )}
              <Th {...TH_PROPS}>Link</Th>
            </Tr>
          </Thead>
          <Tbody>
            {finalGuest.map((d, index) => {
              return (
                <Tr key={`${index}-${d.code}`}>
                  <Td>{index + 1}</Td>
                  <Td>{d.name}</Td>
                  <Td>{d.desc}</Td>
                  <Td>{d.shift === 1 ?"Akad & Resepsi" :"Tasyakuran"}</Td>
                  {ENABLE_DIFFERENT_GUEST_PARTNER_RSVP && (
                    <Td>{d.partner}</Td>
                  )}
                  <Td>
                    <Box display="flex" alignItems="center">
                      <Button
                        marginRight="8px"
                        size="xs"
                        colorScheme="orange"
                        onClick={() => handleClickLink(d)}
                      >
                        Coba Link
                      </Button>
                      <Menu>
                        <MenuButton
                          colorScheme="whiteAlpha"
                          size="xs"
                          as={Button}
                          rightIcon={<FaChevronDown />}
                        >
                          Lainnya
                        </MenuButton>
                        <MenuList color="black">
                          <MenuItem onClick={() => handleCopyLinkOnly(d)}>Salin Link</MenuItem>
                          <MenuItem onClick={() => handleCopyTextBroadcast(d)}>
                            Salin Link + Teks Broadcast
                          </MenuItem>
                          <MenuItem onClick={() => handleCopyTextBroadcast(d, true)}>
                            Kirim via WhatsApp
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Box>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {showList < dataGuest.length && !searchText && (
          <Box margin="16px 0">
            <Stack>
              <Button
                colorScheme="facebook"
                type="button"
                size="sm"
                onClick={() => setShowList(showList + 20)}
              >
                Lihat Lebih Banyak
              </Button>
              <Button
                colorScheme="red"
                type="button"
                size="sm"
                onClick={() => setShowList(dataGuest.length)}
              >
                Tampilkan Semuanya
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Box>
  );
}

ListGuestScreen.propTypes = {
  type: oneOf(['invitation', 'announcement']),
};

export default ListGuestScreen;